.main-header {
  display: flex;
  flex-flow: column;
  height: 100%;
  align-items:center;
  justify-content: center;
}

.userinput {
  display: flex;
  justify-content: center;
}

.box {
  top: 326px;
  left: 1130px;
  width: 60px;
  height: 60px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
  border: 0.5px solid #ADADAD;
  border-radius: 5px;
  opacity: 1;
  margin: 10px;
  text-align: center;
  font: normal normal 900 40px/49px Helvetica Neue;
  letter-spacing: 0px;
  color: #454545;
  opacity: 1;
}

.btn-success {
  color: #0d6efd !important;
  background-color: transparent !important;
  border-color: #0d6efd !important;
  margin-top: 15px;
}

.confirm-btn {
  background-color: #1B73E8 !important;
  border-color: transparent !important;
}

.resend-btn:hover {
	background-color: rgb(10, 167, 36);
}

.header-code {
  background-color: #F27166;
  width: 100%;
  height: 100px;
  display: flex;
}

.head-section {
  margin-top: 10px;
}

.ig-service {
  font: normal normal bold 25px/35px Helvetica Neue;
  letter-spacing: 0px;
  color: #F27166;
  opacity: 1;
  margin-top: 30px;
  text-align: center;
}

.ig-code {
  margin-top: 30px;
  text-align: center;
  font: normal normal bold 18px/23px Helvetica Neue;
  letter-spacing: 0px;
  color: #7E7E7E;
  opacity: 1;
	margin-bottom: 30px;
}

.msg-error-code {
  font-size: 13px;
  position: absolute;
  margin-top: 80px;
  color: red;
  width: 270px;
}

.msg-error-code-2 {
  font-size: 13px;
  position: absolute;
  margin-top: 60px;
  color: red;
  width: 270px;
}

.msg-error-code-s {
  display: flex;
  font-size: 13px;
  justify-content: center;
  color: red;
}

.show-eye {
  position: absolute;
  margin-left: 150px;
  margin-top: 15px;
}

.eye-icon3, .eye-icon4 {
  height: 58px !important; 
  width: 28px;
  color: #212529;
  background-color: #ced4da;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0 0.25rem 0.25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px ;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  .box {
    width: 45px;
    margin: 6px;
  }

  .pass {
    display: flex;
  }

  .confirm-pass {
    display: flex;
  }

  .msg-error-code-s {
    font-size: 13px;
    color: red;
    margin-top: 3px;
  }

  .ig-code {
    margin-top: 21px;
    text-align: center;
    font: normal normal bold 18px/23px Helvetica Neue;
    letter-spacing: 0px;
    color: #7E7E7E;
    opacity: 1;
    margin-bottom: 21px;
    padding: 1px;
  }

  .header-code {
    height: 66px;
  }

  .btns-code {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 100%;
  }
}

@media screen and (min-width: 768px) {
  .pass {
    display: flex;
  }

  .confirm-pass {
    display: flex;
  }

  .inputs-code {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .btns-code {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 100%;
  }
}