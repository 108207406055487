.header-verif {
  background-color: #F27166;
  width: 100%;
  height: 100px;
  display: flex;
  box-shadow: 0px 7px 8px #00000029;  
  align-items: center;
  justify-content: space-around;
}

.code {
  background-color: #EBEDF2;
}

.code-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  background-color: #EBEDF2
}

.h1-confirm {
  text-align: center;
  font: normal normal bold 29px/35px Helvetica Neue;
  letter-spacing: 0px;
  color: #215505;
}

.p-confirm {
  display: flex;
  text-align: center;
  font: normal normal normal 29px/35px Helvetica Neue;
  letter-spacing: 0px;
  color: #215505;
  margin-top: 25px;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .code-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    background-color: #EBEDF2
  }

  .p-confirm {
    display: flex;
    text-align: center;
    font: normal normal normal 29px/35px Helvetica Neue;
    letter-spacing: 0px;
    color: #215505;
    margin-top: 25px;
    margin-bottom: 30px;
  }

  .header-verif {
    background-color: #F27166;
    width: 100%;
    height: 66px;
    display: flex;
    box-shadow: 0px 7px 8px #00000029;  
    align-items: center;
    justify-content: space-around;
  }
};