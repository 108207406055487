.ig {
  background-color: #f9f9f9;
}

.form-control {
  margin-bottom: 5px !important;
}

.recupera-senha {
  font-family: TwitterChirp, -apple-system, "system-ui", "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.recupera-senha {
  align-items: center;
  text-align: center;
  margin: 10% 0px;
  background-color: #ECEDF2;
}

.recupera-senha button{
  min-width: 300px;
  height: 40px;
}

.recupera-senha h1{
  margin-top: 70px;
}

.recupera-senha p{
  margin-top: 40px;
  margin-bottom: 60px;
}
