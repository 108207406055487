* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, main {
  background: #EBEDF2 0% 0% no-repeat padding-box;
}

body {
  font-family: "Lato", sans-serif;
}

.msg-error {
  color: red;
  white-space: nowrap;
}

.msg-error-login {
  color: red;
  margin-left: -147px;
  position: absolute;
  width: 152px;
  font-size: 13px;
  text-align: initial;
}

.text-h1 {
  font: normal normal bold 14px/17px Helvetica Neue;
  letter-spacing: 0px;
  color: #7E7E7E;
  opacity: 1;
  text-align: center;
  margin-bottom: 20px;
}

.main-head{
  height: 150px;
  background: #FFF;
}

.logo {
  margin-bottom: 50px;
}

.sidenav h2, p {
  color: #000;
}

.column {
  display: none;
}

.pull-right {
  display: flex;
  justify-content: space-between;
  align-items:center;
  text-align: center;
}

#invalidCheck {
  margin-bottom: 15px;
  margin-top: -12px;
  width: 12px;
}

.sidenav {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  background-color: #F27166;
  justify-content: center;
}

@media screen and (max-width: 767px) {

  .sidenav {
    display: flex;
    flex-direction: column;
    text-align: center;
    height: 66px;
    background-color: #F27166;
    justify-content: center;
  }

  .logo {
    margin-bottom: 2px;
    width: 50px;
  }
  
  h1 {
    text-align: center;
  }

  /* input do Fazer Login (mobile) */
  /* .login-form {
    display: none;
  } */

  /* h1 e h3 do input Fazer Login (mobile) */
  /* .title-mob1, .info-mob1 {
    display: none;
  } */

  /* h1 e h3 do input Cadastre-se (mobile) */
  /* .title-mob2, .info-mob2 {
    display: none;
  } */

  .login-form {
    margin-top: 4px;
    min-width: 310px;
    position: absolute;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 41px 28px 0px 27px;
    display: flex;
    left: 7%;
  }

  .pull-right {
    text-align: center;
  }

  .text-center {
    margin: auto;
    font-size: 13px;
  }
  
  .login-form-title {
    height: 55px;
    display: flex;
    flex-direction: column;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #1B73E8 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 2px;
    opacity: 1;
    margin-bottom: 30px;
  }

  .title-redefine {
    text-align: left;
    font: normal normal 14px/17px Helvetica Neue;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin: 5px auto;
    font-size: 16px;
    line-height: 24px;
  }

  .main-login{
    display: flex;
    flex-direction: column;
    padding: 0px 37px;
    margin-top: 25%;
    margin-bottom: 130%;
  }

  #invalidCheck {
    background-color: #fff;
    font: inherit;
    color: currentColor;
    width: 0.69em;
    /* height: 1.15em; */
    border: 0.15em solid currentColor;
  }

  .btn-login {
    min-width: 277px;
    height: 40px;
    margin: auto;
    display: block;
    color: #fff;
    background-color: #F27166;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
    border-radius: 4px;
    border: none;
    font-size: 14px;
    cursor: pointer;
  }

  .acesso {
    text-align: center;
    font: normal normal normal 14px/16px Helvetica Neue;
    letter-spacing: 0px;
    color: #585858;
    opacity: 1;
    width: 100px;
    margin-right: 28px;
  }

  .forgot-password {
    display: flex;
    font-size: 13px;
    color: #F27166;
  }

  i {
    position: absolute;
    font-size: 25px;
    color: grey;
    margin: -31px 216.5px 370px;
    padding: -2.625rem 0.75rem;
    cursor: pointer;
  }

  .inv-feed {
    margin-right: -38px;
    margin-bottom: -27px;
  }

  .inv-feed2 {
    margin-right: -38px;
    margin-bottom: -26px;
  }

  .msg-error-login {
    margin-left: -138px;
  }

  .txt-mob1 {
    flex-direction: column;
    margin-top: 75px;
    align-items: center;
    margin-left: 54px;
  }

  .txt-mob2 {
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    align-items: center;
    margin-top: -18px;
  }

  .title-mob1 {
    left: 57px;
    width: 266px;
    height: 53px;
    text-align: center;
    margin-bottom: 84px;
    font: normal normal medium 22px/27px Helvetica Neue;
    font-size: 24px;
    letter-spacing: 0px;
    color: #F27167;
    opacity: 1;
  }

  .info-mob1 {
    top: 241px;
    margin-left: 43px;
    width: 172px;
    height: 32px;
    text-align: center;
    font: normal normal normal 14px/16px Helvetica Neue;
    letter-spacing: 0px;
    color: #F27167;
    opacity: 1;
  }

  .title-mob2 {
    height: 53px;
    text-align: center;
    margin-bottom: 82px;
    font: normal normal medium 22px/27px Helvetica Neue;
    font-size: 24px;
    letter-spacing: 0px;
    color: white;
    opacity: 1;
  }

  .info-mob2 {
    height: 32px;
    text-align: center;
    font: normal normal normal 14px/16px Helvetica Neue;
    letter-spacing: 0px;
    color: white;
    opacity: 1;
  }
}

@media screen and (min-width: 768px){
  .main-login{
    display: flex;
    flex-direction: column;
    margin-left: 40%;
    padding: 0px 10px;
    margin-top: 90px;
    width: 50%;
  }

  .sidenav{
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
  }

  .login-form {
    margin-left: 21.5%;
    position: absolute;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    flex-direction: column !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 50px 53px 0px 53px;
    top: 40%;
  }
  
  .btn-login {
    min-width:300px;
    max-width:300px;
    height: 40px;
    margin: auto;
    display: block;
    color: #fff;
    background-color: #F27166;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
    border-radius: 4px;
    border: none;
    font-size: 14px;
    cursor: pointer;
  }

  .acesso {
    text-align: center;
    font: normal normal normal 14px/16px Helvetica Neue;
    letter-spacing: 0px;
    color: #585858;
    opacity: 1;
  }

  .forgot-password {
    display: flex;
    font-size: 13px;
    color: #F27166;
    margin-left: 50px;
  }

  i {
    position: absolute;
    font-size: 25px;
    color: grey;
    margin: -31px 250px 380px;
    cursor: pointer;
  }

  .txt-mob1 {
    display: none;
  }

  .txt-mob2 {
    display: none;
  }
}

.btn-login:hover {
  background-color: #ef4030;
  transition: all 0.5s;
}

.text-create {
  text-align: center;
  font: normal normal normal 14px/16px Helvetica Neue;
  letter-spacing: 0px;
  color: #585858;
  margin-bottom: 20px;
}

.text-center {
  text-align: center;
  font: normal normal normal 14px/16px Helvetica Neue;
  letter-spacing: 0px;
  color: #585858;
  margin-top: 20px;
}

.grey-line {
  top: 694px;
  left: 1131px;
  width: 299px;
  height: 0px;
  border: 1px solid #D2D2D3;
  opacity: 1;
}

.sidenav h2 {
  font-size: 18px;
  color: #000000;
}

.sidenav a {
  color: #F27166;
  font-size: 15px;
}

.column {
  text-align: center;
  margin-top: 40px;
}

.column h2 {
  font-size: 18px;
  color: #000000;
}

.column a {
  color: #000000;
  font-size: 15px;
}

.inv-feed {
  color: red;
  font-size: 11px;
  position: absolute;
  right: 55px;
  bottom: 272px;
}

.inv-feed2 {
  color: red;
  font-size: 11px;
  position: absolute;
  right: 55px;
  bottom: 202px;
}

.lock-icon {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  margin-left: 290px;
}

.svg-inline--fa {
  margin-right: 1.5px;
  margin-bottom: -39.5px;
  height: 26px;
  width: 51%;
  padding: 0.375rem 0.75rem;
  color: #212529;
  background-color: #ced4da;
  background-clip: padding-box;
  appearance: none;
  border-radius: 0 0.2rem 0.2rem 0;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none;
}

.form-control.is-valid, .was-validated .form-control:valid {
  background-image: none;
}




.msg-error-login2 {
  color: red;
  position: absolute;
  margin-top: 20px;
}

.text-h1-2 {
  font: normal normal bold 14px/17px Helvetica Neue;
  letter-spacing: 0px;
  color: #7E7E7E;
  opacity: 1;
  text-align: center;
  margin-bottom: 20px;
}

.btn-login2 {
  min-width:300px;
  max-width:300px;
}

.pull-right2 {
  display: flex;
  justify-content: space-between;
  align-items:center;
  text-align: center;
  margin-top: 20px;
}

#invalidCheck2 {
  margin-bottom: 15px;
  width: 12px;
}

.forgot-password2 {
  display: flex;
  font-size: 13px;
  color: #0d6efd;
  margin-left: 50px;
}

.acesso2 {
  text-align: center;
  font: normal normal normal 14px/16px Helvetica Neue;
  letter-spacing: 0px;
  color: #585858;
  opacity: 1;
}

@media screen and (max-width: 767px) {
  .text-center2 {
    margin: auto;
    font-size: 13px;
  }

  #invalidCheck2 {
    background-color: #fff;
    font: inherit;
    color: currentColor;
    width: 0.89em;
    height: 1.15em;
    border: 0.15em solid currentColor;
  }
}

.btn-login2 {
  max-width: 20px;
  height: 40px;
  margin: auto;
  display: block;
  color: #fff;
  background-color: #0d6efd;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  border-radius: 4px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 28px;
}

.text-create2 {
  text-align: center;
  font: normal normal normal 14px/16px Helvetica Neue;
  letter-spacing: 0px;
  color: #585858;
  margin-bottom: 20px;
}

.text-center2 {
  text-align: center;
  font: normal normal normal 14px/16px Helvetica Neue;
  letter-spacing: 0px;
  color: #585858;
  position: absolute;
  margin-top: 20px;
  margin-left: 13px;
}

.grey-line2 {
  top: 694px;
  left: 1131px;
  width: 299px;
  height: 0px;
  border: 1px solid #D2D2D3;
  opacity: 1;
}

.inv-feed2 {
  color: red;
  font-size: 11px;
  position: absolute;
}

.form-control2.is-invalid, .was-validated .form-control2:invalid {
  background-image: none;
}

.form-control2.is-valid, .was-validated .form-control2:valid {
  background-image: none;
}
