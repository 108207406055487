.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #EBEDF2
}

.h1-home {
  text-align: center;
  font: normal normal bold 29px/35px Helvetica Neue;
  letter-spacing: 0px;
  color: #215505;
  margin-bottom: 30px;
}