.ig {
  background-color: #f9f9f9;
}

.mainDiv {
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  margin: auto;
  font-family: 'Open Sans', sans-serif;
  margin-top: 50px;
}

.cardStyle {
  width: 500px;
  border-color: white;
  background: #fff;
  padding: 36px 0;
  border-radius: 4px;
  margin: 30px 0;
  box-shadow: 0px 0 2px 0 rgba(0,0,0,0.25);
}

#signupLogo {
  max-height: 100px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.formTitle{
  font-weight: 600;
  margin-top: 20px;
  color: #2F2D3B;
  text-align: center;
}

.inputLabel {
  font-size: 12px;
  color: #555;
  margin-bottom: 6px;
  margin-top: 24px;
}

.inputDiv {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: auto;
}

input {
  height: 40px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  border: solid 1px #ccc;
  padding: 0 11px;
}

input:disabled {
  cursor: not-allowed;
  border: solid 1px #eee;
}

.buttonWrapper {
  margin-top: 40px;
}

.submitButton {
  width: 70%;
  height: 40px;
  margin: auto;
  display: block;
  color: #fff;
  background-color: #0d6efd;
  /* border-color: #085ED6; */
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  border-radius: 4px;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

#loader {
  position: absolute;
  z-index: 1;
  margin: 2px 0 0 20px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #666666;
  width: 14px;
  height: 14px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

button:hover {
  background-color: #e54335;
  transition: all 0.5s;
}

.btn-login-senha {
  min-width:300px;
  max-width:300px;
  height: 40px;
  margin: auto;
  display: block;
  color: #fff;
  background-color: #F27166;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  border-radius: 4px;
  border: none;
  font-size: 14px;
  cursor: pointer;
  margin-top: 10px;
}

@media screen and (min-width: 768px){
  .login-form-senha {
    display: flex;
    flex-direction: column;
    margin-left: 15.2%;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    flex-direction: column !important;
    margin-bottom: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    position: absolute;
    padding: 30px 80px 38px 80px;
  }

  .msg-error-recov {
    color: red;
    margin-top: 5px;
    position: absolute;
  }

  .login-form-senha-2 {
    display: flex;
    flex-direction: column;
    margin-left: 32%;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 460px;
    background: #F27166 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 2px;
    opacity: 1;
    margin-bottom: 30px;
  }

  .title-redefine {
    text-align: left;
    font: normal normal 14px/17px Helvetica Neue;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin: 5px auto;
    font-size: 19px;
    line-height: 24px;
  }

  #main-recov {
    margin-top: 250px;
  }

  .seta {
    width: 50px;
    position: absolute;
    margin-top: 10px;
    margin-left: 16%;
  }

  .seta1 {
    display: none;
  }

  .title-redefine-senha {
    font: normal normal bold 14px/17px Helvetica Neue;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin: 5px auto;
    line-height: 1.2rem;
  }
}

@media screen and (max-width: 767px) {
  .login-form-senha{
    margin-top: 5%;
    min-width:300px;
    max-width:300px;
    margin: auto;
  }

  .login-user {
    display: flex;
    flex-direction: column;
  }

  #main-recov {
    margin-top: 21px;
  }

  .seta {
    display: none;
  }

  .seta1 {
    width: 40px;
    position: absolute;
    margin-top: -16px;
  }

  .title-redefine-senha {
    font: normal normal bold 14px/17px Helvetica Neue;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin: 5px auto;
    line-height: 1.2rem;
  }

  .login-form-senha-2  {
    height: 35px;
    display: flex;
    flex-direction: column;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: #1B73E8 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 3px #00000029;
    border-radius: 2px;
    opacity: 1;
    margin-bottom: 30px;
  }
}

