.header-main {
  background-color: #F27166;
  width: 102%;
  height: 100px;
  display: flex;
  position: absolute;
  top: 0;
  box-shadow: 0px 7px 8px #00000029;
  align-items: center;
  justify-content: space-around;
}

a {
  text-decoration:none;
}

.ahref {
  text-align: center;
  text-decoration: underline;
  font: normal normal normal 14px/16px Helvetica Neue;
  letter-spacing: 0px;
  color: #F27167;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

@media screen and (max-width: 767px) {
  /* Título do cabeçalho e título input Fazer Login (Desktop) ** Deixar sempre NONE ** */
  .header-title, .title-login-desk, .title-signup-desk  {
    display: none;
  }

  .header-main {
    background-color: #F27166;
    width: 100%;
    height: 66px;
    display: flex;
    position: absolute;
    top: 0;
    box-shadow: 0px 7px 8px #00000029;
    align-items: center;
    justify-content: space-around;
  }

  .form-login {
    max-width: 338px;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    flex-direction: column !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 41px 41px 0px 41px;
    margin-top: 78px;
    margin-left: auto;
    margin-right: auto;
  }

  .keep-info {
    display: flex;
    flex-direction: row;
  }

  .keep-access {
    text-align: center;
    font: normal normal normal 14px/16px Helvetica Neue;
    letter-spacing: 0px;
    color: #585858;
    opacity: 1;
    width: 105px;
  }

  .forgot-pass {
    display: flex;
    font-size: 13px;
    color: #F27166;
    margin-left: 20px;
    margin-top: -1px;
  }

  .login-btn {
    min-width: 254px;
    max-width: 20px;
    height: 40px;
    margin: auto;
    display: block;
    color: #fff;
    background-color: #F27166;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
    border-radius: 4px;
    border: none;
    font-size: 14px;
    cursor: pointer;
  }

  .login-btn:hover {
    background-color: #ef4030;
    transition: all 0.5s;
  }

  .txt-center {
    font-size: 13px;
    text-align: center;
    font: normal normal normal 14px/16px Helvetica Neue;
    letter-spacing: 0px;
    color: #585858;
    margin-top: 20px;
    width: 273px;
    margin-left: -10px;
    margin-bottom: -10px;
  }

  .title-login-mobile{
    margin-top: 98px;
  }

  .login-row {
    background: #FAFAFA 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    opacity: 1;
  }

  .signup-row {
    background: #F27166 0% 0% no-repeat padding-box;
    display: table-header-group;
    flex-direction: column;
    opacity: 1;
  }

  .btn-select {
    display: flex;
    justify-content: center;
    margin-top: -91px;
    text-align: center;
  }

  .title-mobile2 {
    text-align: center;
    font: normal normal medium 22px/27px Helvetica Neue;
    letter-spacing: 0px;
    color: #F27166;
    opacity: 1;
  }

  .text-mobile2 {
    text-align: center;
    font: normal normal normal 14px/16px Helvetica Neue;
    letter-spacing: 0px;
    color: #F27166;
    opacity: 1;
    margin-top: 97px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .new-customer {
    width: 148px;
    height: 36px;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F27167;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    font: normal normal 300 18px/22px Helvetica Neue;
    letter-spacing: 0px;
    color: #F27167;
    opacity: 1;
    margin-right: 38px;
  }

  .new-customer:hover {
    background-color: rgb(230, 221, 221);
    transition: all 0.5s;
  }

  .customer {
    width: 148px;
    height: 36px;
    background: #F27167 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F27167;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    font: normal normal bold 18px/23px Helvetica Neue;
    letter-spacing: 0px;
    color: #FAFAFA;
    opacity: 1;
    border-color: white;
  }

  .customer:hover {
    background-color: #d4564d;
    transition: all 0.5s;
  }

  .invalid-field1 {
    color: red;
    font-size: 11px;
    position: absolute;
    margin-left: 161px;
  }

  .invalid-field2 {
    color: red;
    font-size: 11px;
    position: absolute;
    margin-left: 161px;
  }

  .form-signup {
    width: 334px;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    flex-direction: column !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 18px 40px 47px 41px;
    position: initial;
    margin-top: 76px;
    margin-bottom: 318px;
    margin-left: auto;
    margin-right: auto;
  }

  .title-signup-mobile {
    margin-top: 67px;
  }

  .title-singup-mobile2 {
    text-align: center;
    font: normal normal medium 22px/27px Helvetica Neue;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .text-singup-mobile2 {
    text-align: center;
    font: normal normal normal 14px/16px Helvetica Neue;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-top: 96px;
    width: 284px;
    margin-left: auto;
    margin-right: auto;
  }

  .error-step1 {
    font-size: 13px;
    color: red;
    position: absolute;
    margin-left: 3px;
    margin-top: -4px;
  }

  .input-fullname, .input-email {
    width: 252px;
    margin-top: 24px;
    background: #EBEDF2 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
  }

  .input-email {
    margin-bottom: 10px;
  }

  .input-email-login, .input-senha-login {
    background: #EBEDF2 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
  }

  .enter {
    height: 40px;
    max-width: 293px;
    margin: auto;
    display: block;
    color: #fff;
    background-color: #F27166;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 4%);
    border-radius: 4px;
    border: none;
    font-size: 14px;
    cursor: pointer;
    font-weight: bolder;
  }

  .login-div {
    margin-bottom: 21%;
  }
}

@media screen and (min-width: 768px) {
  .title-login-mobile, .title-signup-mobile, .btn-select {
    display: none;
  }

  .new-customer, .customer {
    display: none;
  }

  .row-ig {
    display: flex;
    flex-direction: row;
    height: 50em;
    top:10px
  }

  .header-title {
    text-align: center;
    font: normal normal bold 28px/34px Helvetica Neue;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }

  .login-row, .signup-row  {
    margin-top: 140px;
    width: 50%;
  }

  .login-row {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    background: #F27167 0% 0% no-repeat padding-box;
  }

  .signup-row {
    display: flex;
    justify-content: center;
    background: #EBEDF2 0% 0% no-repeat padding-box;
    margin-top: inherit;
  }

  .form-login {
    min-width: 326px;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    flex-direction: column !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 41px 30px 46px 41px;
    position: absolute;
    margin-top: 206px;
  }

  .form-signup {
    min-width: 326px;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    flex-direction: column !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 18px 40px 94px 41px;
    position: absolute;
    margin-top: 345px;
  }

  .title-desk1 {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    font: normal normal bold 34px/32px Helvetica Neue;
    letter-spacing: 0px;
    text-align: center;
    letter-spacing: NaNpx;
    color: #FFFFFF;
    opacity: 1;
    margin-top: -285px;
  }

  .text-desk1 {
    align-items: center;
    justify-content: center;
    display: flex;
    font: normal normal medium 26px/32px Helvetica Neue;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-top: 29px;
    margin-left: auto;
    margin-right: auto;
    width: 356px;
  }

  .title-desk2 {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    font: normal normal bold 34px/32px Helvetica Neue;
    letter-spacing: 0px;
    color: #F27167;
    margin-top: -2px;
    margin-left: 11px;
    width: 456px;
  }

  .text-desk2 {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    font: normal normal medium 26px/32px Helvetica Neue;
    letter-spacing: 0px;
    color: #F27167;
    margin-top: 29px;
    width: 479px;
  }

  .input-fullname, .input-email {
    width: 289px;
    margin-top: 24px;
    background: #EBEDF2 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
  }

  .title-signup-desk {
    margin-top: 188px;
  }

  .title-login-desk {
    margin-top: 330px;
  }

  .keep-info {
    display: flex;
    flex-direction: row;
  }

  .keep-access {
    text-align: center;
    font: normal normal normal 14px/16px Helvetica Neue;
    letter-spacing: 0px;
    color: #585858;
    opacity: 1;
  }

  .forgot-pass {
    display: flex;
    font-size: 13px;
    color: #F27166;
    margin-left: 60px;
  }

  .login-btn {
    min-width:300px;
    max-width:300px;
    max-width: 20px;
    height: 40px;
    margin: auto;
    display: block;
    color: #fff;
    background-color: #F27166;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
    border-radius: 4px;
    border: none;
    font-size: 14px;
    cursor: pointer;
  }

  .login-btn:hover {
    background-color: #ef4030;
    transition: all 0.5s;
  }

  .txt-center {
    margin: auto;
    font-size: 13px;
    text-align: center;
    font: normal normal normal 14px/16px Helvetica Neue;
    letter-spacing: 0px;
    color: #585858;
    position: absolute;
    margin-top: 20px;
    margin-left: 13px;
  }

  .invalid-field1 {
    color: red;
    font-size: 11px;
    position: absolute;
    right: 42px;
    bottom: 258px;
  }

  .invalid-field2 {
    color: red;
    font-size: 11px;
    position: absolute;
    right: 42px;
    bottom: 189px;
  }

  .msg-error-signin {
    color: red;
    margin-left: 1px;
    position: absolute;
    width: 152px;
    font-size: 13px;
  }

  .error-step1 {
    font-size: 13px;
    color: red;
    position: absolute;
    margin-left: 3px;
    margin-top: -4px;
  }

  .input-email-login, .input-senha-login {
    width: 96%;
    background: #EBEDF2 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
  }


  .enter {
    height: 40px;
    max-width: 293px;
    margin: auto;
    display: block;
    color: #fff;
    background-color: #F27166;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 4%);
    border-radius: 4px;
    border: none;
    font-size: 14px;
    cursor: pointer;
    font-weight: bolder;
  }

  .main-ig {
    margin-left: auto;
    margin-right: auto;
  }
}