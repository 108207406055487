.troca-senha {
  font-family: TwitterChirp, -apple-system, "system-ui", "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.troca-senha {
  align-items: center;
  text-align: center;
  margin: 10% 0px;
  background-color: #ECEDF2;
}

.troca-senha button{
  min-width: 300px;
  height: 40px;
}

.troca-senha h1{
  margin-top: 70px;
}

.troca-senha p{
  margin-top: 40px;
  margin-bottom: 60px;
}

/* button:hover {
  background-color: #085ED6;
  transition: all 0.5s;
}  */