html {
  background-color: #EBEDF2;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  background: #EBEDF2 0% 0% no-repeat padding-box;
}

.logo-ig {
  max-width: 70px;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.logo-ig2 {
  align-items: center;
  justify-content: center;
  margin-left: 5%;
}

#login, #pswrd {
  background:#EBEDF2 0% 0% no-repeat padding-box;
}

.steps {
  width: 190px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 25px;
  margin: 0 auto;
}

.steps2,
.steps3 {
  width: 235px !important;
  margin-bottom: 10px;
  padding-left: 19px;
}

.step-atual {
  margin-right: 15px;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  padding: 10px 18px;
  background-color: #3373e0;
  border-radius: 50%;
}

.step-total {
  color: #6C757D;
  align-self: center;
}

.header-section {
  margin-top: 150px;
}

#first {
  margin-top: 20px;
}

.form-check {
display: flex;
justify-content: center;
}

.row {
  display: flex;
}

.btns {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: end;
}

.btn-cad {
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn-primary {
  width: 191px;
}

.btn-warning {
  width: 191px;
  border-color: transparent !important;
  color: #0d6efd !important;
  background-color: transparent !important;
  border-color: #0d6efd !important;
}

.btns-1 {
  justify-content: center;
  display: flex;
  margin-top: -10px;
}

.form-control.wrong:focus {
  box-shadow: 0 0 1px 1px red;
}

.form-control.correct {
  box-shadow: 0 0 1px 1px green;
}

.btn-cadastro {
  width: 100%;
  height: 40px;
  margin: auto;
  display: block;
  color: #fff;
  background-color: #198754;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
  border-radius: 4px;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

#third {
  margin-top: 20px;
  align-items: center;
  padding: 0 30px 18px 30px;
}

.cel, .cpf, .nasc, .password, .confirm-passord, .endereco {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.needs-validation {
  display: flex;
  border: 0.5px solid #ADADAD;
  border-radius: 5px;
  box-shadow: 0px 1px 3px #00000029;
  flex-direction: column !important;
  margin-bottom: 50px;
  position: relative;
  top: 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 0 70px 40px 70px;
}

/* Header do Step2 e Step3 */
.header-ig1 {
  background-color: #F27166;
  width: 200%;
  height: 100px;
  display: flex;
  position: absolute;
  top: 0;
  box-shadow: 0px 7px 8px #00000029;
}

.ig-title {
  font: normal normal bold 25px/35px Helvetica Neue;
  letter-spacing: 0px;
  color: #F27166;
  opacity: 1;
  top: 171px;
  margin-top: -23px;
}

.ig-instructions {
  margin-top: 30px;
  text-align: center;
  font: normal normal bold 18px/23px Helvetica Neue;
  letter-spacing: 0px;
  color: #7E7E7E;
  opacity: 1;
}

.msg-error {
  font-size: 13px;
  position: absolute;
  margin-top: 62px;
}

.msg-error-cpf {
  font-size: 13px;
  position: absolute;
  margin-top: 70px;
  color: green;
}

#nascimento {
  color: #6F7275;
  background-color: transparent !important;
}

@media screen and (max-width: 767px) {

  /* input do Cadastro (mobile) */
  /* .login-form2 {
    display: none;
  } */

  /* Título do cabeçalho e título input Fazer Login (Desktop) ** Deixar sempre NONE ** */
  .title-header, .txts {
    display: none;
  }

  .header-section {
    margin-top: 120px;
  }

  .steps2, .steps3 {
    width: 235px !important;
    margin-bottom: 10px;
    margin-left: 19px;
}

  #second {
    margin-top: 20px;
    align-items: center;
    padding: 0 49px 20px 44px;

}

  .header-ig1 {
    background-color: #F27166;
    width: 200%;
    height: 66px;
    display: flex;
    position: absolute;
    top: 0;
    box-shadow: 0px 7px 8px #00000029;
  }
  
  .new-cliente {
    width: 148px;
    height: 36px;
    background: #FAFAFA 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F27167;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    font: normal normal 300 18px/22px Helvetica Neue;
    letter-spacing: 0px;
    color: #F27167;
    opacity: 1;
    margin-right: 38px;
  }

  .new-cliente:hover {
      background-color: rgb(230, 221, 221);
      transition: all 0.5s;
  }

  .cliente {
    width: 148px;
    height: 36px;
    background: #F27167 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #F27167;
    border-radius: 5px;
    opacity: 1;
    text-align: center;
    font: normal normal bold 18px/23px Helvetica Neue;
    letter-spacing: 0px;
    color: #FAFAFA;
    opacity: 1;
    border-color: white;
  }

  .cliente:hover {
    background-color: #d4564d;
    transition: all 0.5s;
  }

  .btns-mob {
    position: absolute;
    width: 100%;
    top: 154px;
  }

  .login-form2{
    min-width: 326px;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    flex-direction: column !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 21px 8px 52px 7px;
    margin-top: -22px;
  }

  .btns {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .voltar {
    margin-right: 11px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .avancar {
    margin-top: 18px;
    margin-bottom: 10px;
    height: 40px;
    max-width: 293px;
    margin: auto;
    display: block;
    color: #fff;
    background-color: #F27166;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 4%);
    border-radius: 4px;
    border: none;
    font-size: 14px;
    cursor: pointer;
    font-weight: bolder;
  }

  .avancar:disabled, fieldset:disabled .avancar {
    pointer-events: none;
    opacity: .65;
  }
  .avancar2 {
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100px;
  }
  
  .btn-warning {
    width: 84px;
  }

  .msg-error-ps {
    font-size: 13px;
    color: red;
    margin-top: 62px;
    position: absolute;
    padding-right: 80px
  }

  .password {
    display: flex;
    flex-direction: column;
  }

  #email, #fullName {
    background: #EBEDF2 0% 0% no-repeat padding-box;
    border: 0.5px solid #ADADAD;
    border-radius: 2px;
    opacity: 1;
    width: 292px;
  }

  #email {
    margin-top: 21px;
  }

  .msg-error-step1 {
    display: flex;
    font-size: 13px;
    color: red;
    position: absolute;
  }

  .needs-validation2 {
    display: flex;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    flex-direction: column !important;
    margin: 0 auto;
    margin-bottom: 50px;
    position: relative;
    top: 33px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 345px;
  }

  .needs-validation3 {
    display: flex;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    flex-direction: column !important;
    margin-bottom: 50px;
    position: relative;
    top: 33px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 354px;
  }
  
  .needs-validation {
    display: flex;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    flex-direction: column !important;
    margin-bottom: 50px;
    position: relative;
    top: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 0 30px 33px 30px;
  }

  #cadastrar {
    width: 100%;
  }

  .eye-icon2 {
    margin-bottom: 21.5px;
    height: 58px;
    min-width: 7%;
    padding: 1.025rem 0.75rem;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0 0.25rem 0.25rem 0;
    justify-content: flex-end;
    position: absolute;
    right: 47px;
  }

  #cep, #endereco, #district, #cidade, #estado, #numero, #complemento {
    background: #EBEDF2 0% 0% no-repeat padding-box;
    border: 0.5px solid #ADADAD;
    border-radius: 2px;
    opacity: 1;
    width: 255px;
  }
  
  #celular, #cpf, #nascimento, #senha, #password, #confirma {
    background: #EBEDF2 0% 0% no-repeat padding-box;
    border: 0.5px solid #ADADAD;
    border-radius: 2px;
    opacity: 1;
    min-width: 191px;
  }

  .password {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .confirm-passord {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  #senha {
    max-width: 100%;
  }

  .genero {
    margin-bottom: 15px;
  }

  .header-ig {
    background-color: #F27166;
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    top: 0;
    box-shadow: 0px 7px 8px #00000029;
  }

  .logo-ig {
    max-width: 50px;
    margin: auto;
    align-items: center;
    justify-content: center;
  }

  .txts2 {
    display: none;
  }

  .msg-error-signin {
    color: red;
    margin-left: 1px;
    position: absolute;
    width: 152px;
    font-size: 13px;
  }

  #third {
    margin-top: 20px;
    align-items: center;
    padding: 0 47px 22px 46px;
  }
}

@media screen and (min-width: 768px) {

  #second {
    margin-top: 20px;
    align-items: center;
    padding: 0 13px 1px 16px;
  }

  .login-form2 {
    margin-left: 40%;
    position: fixed;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    flex-direction: column !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 21px 20px 34px 19px;
    top: 41.5%;
  }

  .btns-mob {
    display: none;
  }

  .voltar {
    width: 191px;
  }

  .msg-error-step1 {
    font-size: 13px;
    color: red;
    position: absolute;
    margin-left: -149px;
  }
  
  .msg-error {
    font-size: 13px;
    position: absolute;
    margin-top: 70px;
  }

  .msg-error-cpf {
    font-size: 13px;
    position: absolute;
    margin-top: 70px;
    color: green;
  }

  .msg-error-ps {
    font-size: 13px;
    position: absolute;
    flex-direction: row;
    margin-top: 81px;
    color: red;
    padding-right: 266px;
  }

  .olho1 {
    position: absolute;
    margin-left: 165px;
    margin-bottom: -6px;
  }

  .olho2 {
    position: absolute;
    margin-left: 165px;
    margin-bottom: -6px;
  }

  #email, #fullName {
    min-width:300px;
    max-width:300px;
    background: #EBEDF2 0% 0% no-repeat padding-box;
    border: 0.5px solid #ADADAD;
    border-radius: 2px;
    opacity: 1;
  }

  #email {
    margin-top: 20px;
  }

  .step-footer {
    margin-top: 25px;
  }

  .needs-validation2 {
    display: flex;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    flex-direction: column !important;
    margin: 0 auto;
    margin-bottom: 50px;
    position: relative;
    top: 14px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 0 35px 21px 35px;
  }

  .needs-validation3 {
    display: flex;
    border: 0.5px solid #ADADAD;
    border-radius: 5px;
    box-shadow: 0px 1px 3px #00000029;
    flex-direction: column !important;
    margin-bottom: 50px;
    position: relative;
    top: 14px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 0 46px 8px 46px;
  }  

  .avancar {
    height: 40px;
    display: block;
    color: #fff;
    background-color: #F27166;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 4%);
    border-radius: 4px;
    border: none;
    font-size: 14px;
    cursor: pointer;
    font-weight: bolder;
    margin-top: -25px;
  }

  .avancar:hover {
    background-color: #ef4030;
    transition: all 0.5s;
    font-weight: bolder;
    margin-top: -25px;
  }

  .avancar:disabled, fieldset:disabled .avancar {
    pointer-events: none;
    opacity: .65;
  }

  .btn-step3 {
    margin-top: 10px;
  }

  .eye-icon2 {
    margin-left: 27.3%;
    margin-bottom: 21.5px;
    height: 58px;
    width: 7%;
    padding: 1.025rem 0.75rem;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0 0.20rem 0.20rem 0;
    justify-content: flex-end;
    position: absolute;
  }

  #cep, #endereco, #district, #cidade, #estado, #numero, #complemento {
    background: #EBEDF2 0% 0% no-repeat padding-box;
    border: 0.5px solid #ADADAD;
    border-radius: 2px;
    opacity: 1;
  }
  
  #celular, #cpf, #nascimento, #senha, #confirma {
    background: #EBEDF2 0% 0% no-repeat padding-box;
    border: 0.5px solid #ADADAD;
    border-radius: 2px;
    opacity: 1;
  }

  .main-title {
    color: white;
    font-size: 28px;
    font-weight: bold;
  }

  .main-info {
    color: white;
    width: 50%;
    margin: auto;
    margin-top: 2%;
  }

  .txts {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 50%;
  }

  .main-title2 {
    color: #F27166;
    font-size: 28px;
    font-weight: bold;
  }

  .main-info2 {
    color: #F27166;
    width: 50%;
    margin: auto;
    margin-top: 2%;
  }

  .txts2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 50%;
    position: fixed;
    margin-left: 20%;
    top: 19%;
    width: 70%;
  }

  .title-header {
    color: white;
    font-size: 24px;
    font-weight: bold;
    margin-top: 34px;
    margin-right: 2%;
  }

  .header-ig {
    background-color: #F27166;
    width: 200%;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 0;
    box-shadow: 0px 7px 8px #00000029;
  }
}

.Form.Control {
  color: #6F7275;
}

.blue-line {
  position: absolute;
  margin-top: 22px;
  margin-right: 75px;
  width: 10px;
  height: 0px;
  border: 1px solid #3373e0;
  opacity: 1;
}

.blue-line-2 {
  position: absolute;
  margin-top: 22px;
  margin-left: 46px;
  width: 10px;
  height: 0px;
  border: 1px solid #3373e0;
  opacity: 1;
}

.greyy-line {
  position: absolute;
  margin-top: 22px;
  margin-left: 46px;
  width: 10px;
  height: 0px;
  border: 1px solid #D2D2D3;
  opacity: 1;
}

.step-second-grey, .step-third-grey {
  margin-right: 15px;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  padding: 10px 18px;
  background-color: #D2D2D3;
  border-radius: 50%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
